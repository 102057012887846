import usePrefix from '@/hooks/usePrefix';
import Link from 'next/link';
import React from 'react';

type ButtonStatusProps = {
  status: string;
  facility_url_code: string;
  hoverIndex?: number;
  currentIndex?: number;
  minDays?: number;
  maxDays?: number;
  configInfo?: {};
  checkin?: string;
  checkout?: string;
  isCardList?: boolean;
  errorMessage?: any;
};

function ButtonStatus({
  status,
  minDays,
  maxDays,
  isCardList,
}: ButtonStatusProps) {
  function handleErrorMessage(status) {
    switch (status) {
      case 'unavailable':
        return (
          <>
            {minDays > 0 && maxDays > 0 && (
              <small className="text-xs">
                This facility requires a minimun of <strong>{minDays}</strong>{' '}
                and max of <strong>{maxDays}</strong> days.{' '}
                <span
                  className="text-[#03A1CF] underline cursor-pointer pointer-events-auto"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  }
                >
                  Change the dates!
                </span>
              </small>
            )}
            {maxDays === 0 && minDays > 0 && (
              <small className="text-xs">
                This facility requires a minimum of <strong>{minDays}</strong>{' '}
                days.{' '}
                <span
                  className="text-[#03A1CF] underline cursor-pointer pointer-events-auto"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  }
                >
                  Change the dates!
                </span>
              </small>
            )}
            {minDays === 0 && maxDays > 0 && (
              <small className="text-xs">
                This facility requires a max of <strong>{maxDays}</strong> days.
                <span
                  className="text-[#03A1CF] underline cursor-pointer pointer-events-auto"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  }
                >
                  Change the dates!
                </span>
              </small>
            )}
          </>
        );
      case 'soldout':
        return (
          <small className="text-xs">
            This facility is sold out for this period.{' '}
            <span
              className="text-[#03A1CF] underline cursor-pointer pointer-events-auto"
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
              Change the dates!
            </span>
          </small>
        );
    }
  }

  return status === 'unavailable' || status === 'soldout' ? (
    <>
      <div
        className={` ${
          isCardList
            ? 'flex items-center justify-center w-[5.5rem] h-5 self-start p-4 py-1.5 rounded-md bg-gray-500 text-[0.625rem] font-bold text-white mb-2'
            : 'rounded-md flex items-center justify-center text-start font-semibold text-white cursor-default h-6 w-40 absolute right-4 top-2 bg-deal pl-2 text-xs italic'
        }`}
      >
        {status === 'soldout' ? 'Sold Out' : 'Unavailable'}
      </div>
      {handleErrorMessage(status)}
    </>
  ) : (
    <></>
  );
}

export default ButtonStatus;
